import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0048 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="勤務間インターバル制度とは？制度やメリットについて解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.04.14</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        ワーク・ライフ・バランスや働き方改革の推奨、そして新型コロナウイルス感染症蔓延の影響により、社員の働き方は多様化しています。そのような中で新たに注目されている働き方の一つが
        <span>「勤務間インターバル制度」</span>
        です。今回は、この勤務間インターバル制度の概要やメリットなどの特徴、導入の流れについて順を追って解説をしていきましょう。
      </p>
      <img src="/images/column/details/c0048.jpg" alt="キンクラコラム画像48" />

      <h1 id="toc1">勤務間インターバル制度とは</h1>
      <p>
        勤務間インターバル制度とは、前の日に仕事を終えて退社をする時刻から、その次の日に仕事をするために出勤をする時刻の間に
        <span>一定の「休息時間」を作る</span>
        ことにより、日々働き続ける社員が日常生活を送るため、または睡眠などで休息を取るためのまとまった時間を作り、その社員の健康を保持するため、そして長時間労働による心身疲労を防ぐための制度です。
        <br />
        なお、「休息時間」のことをインターバル時間と呼ぶことから、勤務間インターバル制度という名称がつけられています。
        <br />
        <br />
        制度化する前にも一定時間の休息を付与する企業自体はみられたものの、
        <span>
          2019年4月より各企業に対して勤務間インターバル制度の導入が努力義務化された
        </span>
        ことで、新たな働き方として注目される運びとなりました。
      </p>

      <h1 id="toc2">制度の具体例</h1>
      <p>
        たとえば、9時から18時までの間を所定労働時間としており（うち休憩時間1時間あり）、11時間のインターバル時間を確保しなければならない会社を例に挙げてみましょう。
        <br />
        <br />
        この会社の社員が20時まで残業をした場合、
        <span>業務を終了した20時から翌日7時までの11時間がインターバル時間</span>
        となり、社員の休息に充てなければなりません。
        <br />
        <br />
        また、同じケースで夜中の0時まで残業をした場合、11時間のインターバル時間を確保するためには、業務を終了した0時から翌日11時までが休息時間になります。
        <br />
        この場合は、社員の始業時間を本来の会社始業時間である9時から11時へ
        <span>
          後ろ倒しにする方法、もしくは本来の会社始業である9時から11時までの間を「働いたもの」としてみなす方法のいずれかを取る
        </span>
        必要があります。
      </p>

      <h1 id="toc3">勤務間インターバル制度が適する業種とは</h1>
      <p>
        勤務間インターバル制度は業種・企業規模などの制約がなく、いわば
        <span>どのような形態の会社であっても導入が可能</span>です。
        <br />
        <br />
        その中でも、特に集中力を要するパソコン業務が主となるIT業界では、いつの間にか時間が経過し長時間の労働になるケースが多くみられます。
        <br />
        また、変形労働時間制を多く用いる傾向のある建設業界や小売業の場合、繁忙期には連続した長時間労働が組み込まれている場合があり、休息時間が取りづらい状況に置かれるケースもあります。
        <br />
        <br />
        休みなく続けられる長時間労働は、社員が疲弊し心身に支障をきたす恐れがあり、会社の生産性向上の妨げになる場合や深刻な労使トラブルへ発展する場合があります。
        <br />
        勤務間インターバル制度の導入による休息時間の確保措置を取ることは、これらの危険性に対応し、
        <span>社員のみならず会社をも守る</span>ことへと繋がります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc4">勤務間インターバル制度のメリット</h1>
      <p>
        勤務間インターバル制度は、前述の通り社員・会社の双方に対して、次のような効果が期待されています。
      </p>
      <h2 id="toc4-1">①社員が心身ともに健康な状態で働ける</h2>
      <p>
        長時間労働は、社会問題化して久しい状況にあります。国でも法律による時間外労働の規制を行ってはいるものの、36協定を届け出ることで一定時間の残業が認められる現状では、長時間労働を徹底的に取り締まることが難しいことであるといえます。
        <br />
        勤務間インターバル制度は、このような
        <span>
          長時間労働が強いられている社員に一定時間のオフタイムを設けることで仕事に対するストレスを軽減させ、心も体も万全の状態で働くことができる
        </span>
        でしょう。
      </p>
      <h2 id="toc4-2">②優秀な人材確保</h2>
      <p>
        少子高齢化の流れを受け、全国的に労働力の人口が社会問題として取りざたされています。このような中、経験やスキルを保持する優秀な労働者を確保するためには、社員が「この会社で働きたい」と望むような職場環境づくりが必須です。勤務間インターバル制度の導入により十分な休息時間を付与するという点についてはこのような社員に対する
        <span>会社のアピールポイント</span>
        となり、既存社員の流出を防ぎ、新たな人材確保のチャンスへとつながります。
      </p>
      <h2 id="toc4-3">③企業発展へのきっかけ</h2>
      <p>
        人間は、一定時間を休息に充てることで心身がリフレッシュされ、だらだら長時間働き続けるケースに比べると気力や体力がアップした状態で働くことが可能になります。また、勤務間インターバル制度により仕事の時間とプライベートな時間が交互に確保されることで、メリハリのある生活を送ることができます。その結果、
        <span>
          各社員が集中して仕事に取り組む状況が整い、会社の生産性アップへとつながる効果が期待されます。
        </span>
      </p>

      <h1 id="toc5">導入することで助成金の対象にも</h1>
      <p>
        勤務間インターバル制度を新たに導入することで、
        <span>
          「働き方改革推進支援助成金勤務間インターバル導入コース｣という助成制度
        </span>
        の対象となります。
        <br />
        <br />
        制度の対象は、36協定を届け出ており時間外労働が営まれている企業で、そもそも勤務間インターバル制度を導入していない企業、制度を導入していても対象労働者が社内の半分に満たない企業、すでに9時間未満の勤務間インターバル制度を導入している企業の3種類です。
        <br />
        これらの企業が研修や専門家による助言指導の享受、労務管理機器の導入、就業規則の変更などの取り組みを実施した場合に、取り組みでかかった経費の一部が最大100万円までの範囲で助成される仕組みとなります。
      </p>
      <h1 id="toc6">まとめ</h1>
      <p>
        勤務間インターバル制度は、働き方改革の推進が更に求められている昨今の状況にマッチした制度であり、導入することで労働者・会社ともにメリットが期待され、助成金の対象にもなる至れり尽くせりの制度です。まずは自社の社員がどの程度働いているか、残業時間はどのくらいかを調査した上で、具体的な制度内容の検討、導入に向けた準備を始めてみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0048
